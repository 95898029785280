import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const GoBackButton = () => {
      const history = useHistory();

  return (
    <Button
      onClick={() => history.goBack()}
      startIcon={<ArrowBackIcon />}
    >
      Go Back
    </Button>
  );
};
export default GoBackButton;