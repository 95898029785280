import React, { useState } from "react";

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";


const EditResellerUser = (props) => {
  const { userInfo, setLoading, callOnSuccess } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [name, setName] = useState(userInfo.full_name);
  const [email, setEmail] = useState(userInfo.email);
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordUpdate, setIsPasswordUpdate] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const updatePassword = () => {
    const updateData = {
      uuid: userInfo.uuid,
      full_name: name,
      email,
    };
    if (isPasswordUpdate) {
      updateData.password = newPassword;
    }

    axios
      .post(`/auth/update-details`, updateData)
      .then(({ data }) => {
        setMessage({
          type: data.success ? "success" : "error",
          text: data.message,
        });
        callOnSuccess();
      })
      .catch((error) => {
        setMessage({
          type: "error",
          text: error.response.data.detail,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Tooltip title="Update Details">
        <IconButton
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Update Details for {userInfo.full_name}{" "}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}
          <Box pb={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="full_name"
                  label="Full Name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Full Name"
                  value={name}
                />
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                  value={email}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Check to update Password">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPasswordUpdate}
                        onChange={(e) => setIsPasswordUpdate(e.target.checked)}
                        color="secondary"
                      />
                    }
                    label="Update Password"
                    value={isPasswordUpdate}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="password"
                  label="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter New Password"
                  value={newPassword}
                  disabled={!isPasswordUpdate}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={updatePassword} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

EditResellerUser.propTypes = {
  userInfo: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  callOnSuccess: PropTypes.func.isRequired,
};

export default EditResellerUser;
