import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Tooltip,
  Typography,
  Chip,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import SpeedIcon from "@material-ui/icons/Speed";
import { GREEN, RED, YELLOW } from "../utils/constants";
import moment from "moment";

const SpeedTest = (props) => {
  const { setLoading, speed_test_type,name } = props;
  const [message, setMessage] = useState();
  const { device_uuid } = useParams();
  const [open, setOpen] = useState(false);
  const [speedTestResult, setSpeedTestResult] = useState(undefined);
  const [attributes, setAttributes] = useState([]);

  const refreshInterval = useRef();

  useEffect(() => {
    if (open) {
      initiateSpeedTest();
      refreshInterval.current = setInterval(() => {
        getSpeedTestResult();
      }, 10000);
    } else {
      clearInterval(refreshInterval.current);
    }

    return () => clearInterval(refreshInterval.current);
    // eslint-disable-next-line
  }, [open]);

  const initiateSpeedTest = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/speed-test`, {
        params: { speed_test_type },
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: "success", text: response.data.message });
        }
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.response.data.detail });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSpeedTestResult = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/speed-test-result`)
      .then((response) => {
        if (response.status === 200) {
          setSpeedTestResult(response.data.cells[0]);
        }
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.response.data.detail });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (speedTestResult && speedTestResult.str_text) {
      setAttributes(speedTestResult.str_text.split("\n"));
        clearInterval(refreshInterval.current);
    }
  }, [speedTestResult]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<SpeedIcon />}
        onClick={handleClickOpen}
      >
        {name} Speed Test
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {name} Speed Test Result
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}

          {speedTestResult && (
            <Box p={3}>
              <TableContainer component={Paper}>
                <Table aria-label="profile table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>
                        <Chip
                          label={
                            speedTestResult.str_result === 2
                              ? "SUCCESS"
                              : speedTestResult.str_result === 0
                              ? "In Progress"
                              : speedTestResult.str_result === 4
                              ? "COMMAND ERROR"
                              : ""
                          }
                          size="small"
                          style={{
                            fontWeight: 500,
                            background:
                              speedTestResult.str_result === 2
                                ? GREEN
                                : speedTestResult.str_result === 0
                                ? YELLOW
                                : speedTestResult.str_result === 4
                                ? RED
                                : "",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Last Updated At</TableCell>
                      <TableCell>
                        <Tooltip
                          title={moment(speedTestResult.str_time * 1000)
                            .local()
                            .format("lll")}
                        >
                          <Typography>
                            {moment(speedTestResult.str_time * 1000)
                              .local()
                              .fromNow()}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>

                    {attributes.length !== 0 &&
                      attributes.map((attribute) => {
                        return (
                          <TableRow>
                            <TableCell>{attribute.split("=")[0]} </TableCell>
                            <TableCell>{attribute.split("=")[1]}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

SpeedTest.propTypes = {
  setLoading: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  speed_test_type:PropTypes.number.isRequired,
};

export default SpeedTest;
