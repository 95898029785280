import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import { useParams } from "react-router-dom";

const AddUser = (props) => {
  const { reseller_uuid } = useParams();
  const { refreshUsers } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [userFullName, setUserFullName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isClientManager, setIsClientManager] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createClient = () => {
    setLoading(true);
    axios
      .post(`/reseller/${reseller_uuid}/user`, {
        full_name: userFullName,
        email,
        password,
        is_reseller_manager: isClientManager,
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: "success", text: "User Created Successfully." });
          refreshUsers();
        }
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.response.data.detail });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Add User
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}
          <Box p={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Full Name"
                  onChange={(e) => setUserFullName(e.target.value)}
                  placeholder="Enter Full Name"
                />
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                  type="email"
                />
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="password"
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                />
              </Grid>

              <Grid item>
                <Tooltip title="Can create reseller users and sub reseller">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isClientManager}
                        onChange={(e) => setIsClientManager(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Reseller Manager"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={createClient}
            color="primary"
            disabled={
              loading ||
              userFullName === undefined ||
              userFullName === "" ||
              email === undefined ||
              email === "" ||
              password === undefined ||
              password === ""
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

AddUser.propTypes = {
  refreshUsers: PropTypes.any.isRequired,
};

export default AddUser;
