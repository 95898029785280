import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableCell from "./TableCell";
import TableHeadCell from "./TableHeadCell";
import axios from "axios";
import PropTypes from "prop-types";

const DevicesList = (props) => {
  const { reseller_uuid, setError, isLoading } = props;
  
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);

  const getDevices = () => {
    setLoading(true);
    axios
      .get(`/reseller/${reseller_uuid}/devices`)
      .then((response) => {
        setDevices(response.data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isLoading === false) {
      getDevices();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <React.Fragment>
      <Box pt={2} pb={3}>
        {devices && devices.length !== 0 && (
          <React.Fragment>
            <Typography variant="h6" align="right">
              Total Devices Assigned : {devices && devices.length}
            </Typography>

            <TableContainer component={Paper}>
              <Table size="small" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Serial Number</TableHeadCell>
                    <TableHeadCell>Name</TableHeadCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {devices.map((item) => {
                    return (
                      <TableRow key={item.uuid}>
                        <TableCell>{item.serial_no}</TableCell>
                        <TableCell>{item.name}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </Box>

      {!loading && devices && devices.length === 0 && (
        <Box pt={10}>
          <Typography variant="h5" align="center">
            No Devices assigned.
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

DevicesList.propTypes = {
  reseller_uuid: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
};

export default DevicesList;
