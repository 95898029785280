import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    TextField,
    Button
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../layouts/Layout";
import DevicesList from "../components/DevicesList";
import UnarchiveIcon from "@material-ui/icons/Unarchive";

const AssignDevices = ()=>{
    const { reseller_uuid } = useParams();

    const [loading, setLoading] = useState(false);
    const [resellerData,setResellerData] = useState(undefined);
    const [message, setMessage] = useState();
    const [assignDevices, setAssignDevices] = useState("");
      const [error, setError] = useState(undefined);


    const getResellerDetail = () => {
      setLoading(true);
      axios
        .get(`/reseller/${reseller_uuid}`)
        .then((response) => {
          setResellerData(response.data);
        })
        .catch((error) => {
          setError(error.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const assignDevicesToClient = () => {
        let data = {
          devices:
            assignDevices.trim() === "" ? [] : assignDevices.trim().split("\n"),
        };
        setLoading(true);
        axios
          .post(`/reseller/${reseller_uuid}/assign-devices`, data)
          .then((response) => {
            if (response.data.success){
                setMessage(response.data.message);
            } else{
              setError(response.data.message);
            }
          })
          .catch((error) => {
            setError(error.response.data.detail);
          })
          .finally(() => {
            getResellerDetail();
            setLoading(false);
          });
    };

    const unAssignDevicesToClient = () => {
      let data = {
        devices:
          assignDevices.trim() === "" ? [] : assignDevices.trim().split("\n"),
      };
      setLoading(true);
      axios
        .post(`/reseller/${reseller_uuid}/unassign-devices`, data)
        .then((response) => {
          if (response.data.success) {
            setMessage(response.data.message);
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          setError(error.response.data.detail);
        })
        .finally(() => {
          getResellerDetail();
          setLoading(false);
        });
    };

    useEffect(() => {
        getResellerDetail();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
      <Layout
        pageTitle={`Manage Devices for ${resellerData?.name}`}
        error={error}
        successMessage={message}
        loading={loading}
        containerMaxWidth="lg"
      >
        <Box pt={6}>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={8} lg={6}>
              <TextField
                id="outlined-multiline-static"
                label="Devices to Assign/Unassign"
                multiline
                minRows={5}
                variant="outlined"
                fullWidth
                value={assignDevices}
                onChange={(e) => {
                  setAssignDevices(e.target.value);
                }}
              ></TextField>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={assignDevicesToClient}
                startIcon={<SendIcon />}
              >
                Assign Devices
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={unAssignDevicesToClient}
                startIcon={<UnarchiveIcon />}
              >
                Un-Assign Devices
              </Button>
            </Grid>
          </Grid>
        </Box>
        {resellerData && (
          <DevicesList
            key={resellerData.uuid}
            reseller_uuid={resellerData.uuid}
            setError={setError}
            isLoading={loading}
          />
        )}
      </Layout>
    );
}

export default AssignDevices;