import revgen_logo from "../partners/revgen/logo.png";
import netrifi_logo from "../partners/netrifi/logo.png";
import default_logo from "../partners/default/signin.png";
import echo911_logo from "../partners/echo911/logo.png";
import device_manager_logo from "../partners/default/device_manager_logo.png";
import { createTheme } from "@material-ui/core/styles";

const PARTENERS = [
  { name: "revgen", domain: "cloud.revgennetworks.com" },
  { name: "netrifi", domain: "cloud.netrifi.com" },
  { name: "echo911", domain: "vsim.echo911.com" },
];

const getPartner = () => {
  for (const partner of PARTENERS) {
    if (partner.domain === window.location.host) {
      return partner.name;
    }
  }
};
export const isNetrifi = () => {
  return getPartner() === "netrifi";
}

export const isEcho911 = () => {
  return getPartner() === "echo911";
};

export function getLogoPath() {
   const partner = getPartner();
  if (partner === "revgen") {
    return revgen_logo;
  } else if (partner === "netrifi") {
    return netrifi_logo;
  } else if (partner === "echo911") {
    return echo911_logo;
  }
  return default_logo;
}


export function getHeaderLogo(){
   const partner = getPartner();

  if (partner === "revgen") {
    return revgen_logo;
  } else if (partner === "netrifi") {
    return netrifi_logo;
  } else if (partner === "echo911") {
    return echo911_logo;
  }
  return device_manager_logo;

}
export function getTheme() {
    const partner = getPartner();

  if (partner === "revgen") {
    return revgenTheme;
  } else if (partner === "netrifi") {
    return netrifiTheme;
  }
  return defaultTheme;
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#1D4354",
    },
    secondary: {
      main: "#6FDA44",
    },
    warning: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
});

const revgenTheme = createTheme({
  palette: {
    primary: {
      main: "#930622",
    },
    secondary: {
      main: "#000000",
    },
    warning: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
  overrides: {
    // Style sheet name ⚛️
    MuiAppBar: {
      // Name of the rule
      colorPrimary: {
        backgroundColor: "#9e9e9e",
      },
    },
  },
});



const netrifiTheme = createTheme({
  palette: {
    primary: {
      main: "#072c40",
    },
    secondary: {
      main: "#1a95bc",
    },
    warning: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
  //   overrides: {
  //     // Style sheet name ⚛️
  //     MuiAppBar: {
  //       // Name of the rule
  //       colorPrimary: {
  //         backgroundColor: "#9e9e9e",
  //       },
  //     },
  //   },
});

