import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { RefreshOutlined } from "@material-ui/icons";

const RefreshButton = (props) => {
      const { refreshAction } = props;

  return (
    <Button onClick={refreshAction} color="primary" variant="contained" startIcon={<RefreshOutlined />}>
      Refresh
    </Button>
  );
};


RefreshButton.propTypes = {
  refreshAction: PropTypes.func.isRequired,
};



export default RefreshButton;