import React from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
} from "@material-ui/core";
import { GREEN, RED, YELLOW } from "../utils/constants";
import PropTypes from "prop-types";
import axios from "axios";
import { useParams } from "react-router-dom";

const PortStatus = (props) => {
  const { device_uuid } = useParams();
  const { status, status_formatted, setError, setLoading, setMessage } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reenablePort = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/activate-gsm-port`)
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
      });
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item>
          <Chip
            label={status_formatted}
            size="small"
            style={{
              fontWeight: 500,
              background:
                status === 3
                  ? GREEN
                  : status === 0 || status === 1 || status === 14
                  ? YELLOW
                  : RED,
            }}
          />
        </Grid>
        {/* When Port is Deactivated */}
        <Grid item>
          {status === 500 && (
            <div>
              <Link
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                style={{ cursor: "pointer" }}
              >
                Re-enable port
              </Link>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle
                  style={{ cursor: "move" }}
                  id="draggable-dialog-title"
                >
                  Re-enable Port
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    This device had too many failed registrations caused by poor
                    cellular coverage. Please move the device to a better
                    location. Please click Re-enable button here once completed.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                  <Button onClick={reenablePort} color="primary" variant="contained">
                    Re-enable
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

PortStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

export default PortStatus;
