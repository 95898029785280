import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableHead,
  TableRow,
  Tooltip,
  IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import TableHeadCell from "./TableHeadCell";
import axios from "axios";
import { HistoryOutlined } from "@material-ui/icons";
import moment from "moment";

const DeviceActionHistory = (props) => {
  const [actionData, setActionData] = useState([]);
  const { device_id, setLoading, serial_no } = props;
 const [message, setMessage] = useState();

  const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };


  useEffect(() => {
    if (device_id !== null && open) {
      setLoading(true);
      axios
        .get(`/device/${device_id}/history`)
        .then(({ data }) => {
          setActionData(data);
          setLoading(false);
        })
        .catch((error) => {
          setMessage({ type: "error", text: error.response.data.detail });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [open]);

    return (
      <>
          <Tooltip title="Action History">
            <IconButton onClick={handleClickOpen} size="small">
              <HistoryOutlined />
            </IconButton>
          </Tooltip>
        <Dialog
          onClose={handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            {serial_no} Action History
          </DialogTitle>
          <DialogContent>
            <React.Fragment>
              {message && (
                <Box pt={3}>
                  <Alert severity={message.type}> {message.text} </Alert>
                </Box>
              )}
              {actionData && (
                <TableContainer component={Paper}>
                  <Table size="small" style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>Action</TableHeadCell>
                        <TableHeadCell>Old Value</TableHeadCell>
                        <TableHeadCell>New Value</TableHeadCell>
                        <TableHeadCell>Timestamp</TableHeadCell>
                        <TableHeadCell>User</TableHeadCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {actionData.length !== 0 &&
                        actionData.map((item) => {
                          return (
                            <TableRow>
                              <TableCell>{item.action} </TableCell>
                              <TableCell>
                                {item.old_value && item.old_value}
                              </TableCell>
                              <TableCell>
                                {item.new_value && item.new_value}
                              </TableCell>
                              <TableCell>
                                {moment
                                  .utc(item.created_at)
                                  .local()
                                  .format("LLL")}
                              </TableCell>
                              <TableCell>{item.taken_by.full_name}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </React.Fragment>
          </DialogContent>
        </Dialog>
      </>
    );
};

export default DeviceActionHistory;
