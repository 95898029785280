import React from "react";
import { Chip } from "@material-ui/core";
import { GREEN, RED, YELLOW, BLUE, GREY } from "../utils/constants";
import PropTypes from "prop-types";

const DeviceStatus = (props) => {
  const { status, status_formatted } = props;
  return (
    <Chip
      label={
        status_formatted
      }
      size="small"
      style={{
        fontWeight: 500,
        background:
          status === 1
            ? GREEN
            : status === 0
            ? RED
            : status === 2
            ? YELLOW
            : status === 12 || status === 13 || status === 14
            ? GREY
            : status === 3
            ? BLUE
            : "",
        color: status === "Failed",
      }}
    />
  );
};

DeviceStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

export default DeviceStatus;
