export const GREEN = "#4caf50a3";
export const RED = "#ff000073";
export const YELLOW = "#ffc107bd";
export const BLUE = "#2196f3a6";
export const GREY = "#9e9e9e";

export const SPEED_TEST_TYPES = {
    IPERF : 6,
    NORMAL : 5
}

export const NDIAG_STEPS = {
    0: "None",
    1 : "Diag Start",
    2 : "Net Scan Start",
    3: "Net Scan End",
    10 : "Sim Allocate",
    11: "iPERF Start",
    12 : "iPERF End",
    30: "Diag End"
}
