import React, { useState } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Grid,
  Tooltip,
  IconButton,
  DialogActions,
  Typography,
} from "@material-ui/core";
import Loader from "./Loader";
import axios from "axios";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { red } from "@material-ui/core/colors";

const DeactivateDevice = (props) => {
  const [loading, setLoading] = useState(false);
  const { deviceData, setMessage, setError } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const deActivateDevice = () => {
    setLoading(true);
    axios
      .get(`/device/${deviceData.uuid}/deactivate`)
      .then(({ data }) => {
        if (data.success) {
          setMessage(data.message);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
        handleClose()
      });
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Grid item>
          <Tooltip title="De-activate Device">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
              size="small"
            >
              <HighlightOffIcon
                style={{
                  color: red[500],
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Dialog
          onClose={handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            De-Activate Device {deviceData?.name}
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to deactivate the device with serial no : {" "}
              {deviceData?.serial_no} ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={deActivateDevice}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default DeactivateDevice;
