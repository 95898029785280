import React, { useState } from "react";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { AppBar, Tab } from "@material-ui/core";

import DeviceBridgedIP from "./DeviceBridgedIP";
import DeviceDedicatedIP from "./DeviceDedicatedIP";
import DevicePortForwarding from "./DevicePortForwarding";

const TABS = {
  portForward: "portforward",
  dedicatedIps: "dedicatedips",
  bridgedIps: "brideips",
};

const DeviceNetwork = (props) => {
  const [tabValue, setTabValue] = useState(TABS.portForward);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <TabContext value={tabValue}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Port Forwarding" value={TABS.portForward} />
            <Tab label="Dedicated IPs" value={TABS.dedicatedIps} />
            <Tab label="Bridge IPs" value={TABS.bridgedIps} />
          </TabList>
        </AppBar>
        <TabPanel value={TABS.portForward}>
          <DevicePortForwarding
            deviceSerial={props.deviceSerial}
            setError={props.setError}
            setMessage={props.setMessage}
          />
        </TabPanel>
        <TabPanel value={TABS.dedicatedIps}>
          <DeviceDedicatedIP
            deviceSerial={props.deviceSerial}
            setError={props.setError}
            setMessage={props.setMessage}
          />
        </TabPanel>
        <TabPanel value={TABS.bridgedIps}>
          <DeviceBridgedIP
            deviceSerial={props.deviceSerial}
            setError={props.setError}
            setMessage={props.setMessage}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default DeviceNetwork;
