import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import DevicesIcon from "@material-ui/icons/Devices";
import ListIcon from "@material-ui/icons/List";
import PermScanWifiIcon from "@material-ui/icons/PermScanWifi";
import { useHistory } from "react-router-dom";
import Layout from "../layouts/Layout";
import axios from "axios";
import { blue } from "@material-ui/core/colors";
import GroupIcon from "@material-ui/icons/Group";
import SimCardIcon from "@material-ui/icons/SimCard";
import { isSuperAdmin } from "../auth/roles";
import EditReseller from "../components/EditReseller";
import DefaultSsidModal from "./DefaultSsidModal";

const Resellers = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [ssidModal, setSsidModal] = useState(false);

  const [resellerName, setResellerName] = useState();
  const [bwCustomerId, setBwCustomerId] = useState();
  const [resellerData, setResellerData] = useState([]);

  const getClients = () => {
    setLoading(true);
    axios
      .get("/resellers")
      .then((response) => {
        setResellerData(response.data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createClient = () => {
    setLoading(true);
    const inputData = {
      name: resellerName,
    };
    if (bwCustomerId) {
      inputData.bw_customer_id = bwCustomerId;
    }
    axios
      .post("/reseller", inputData)
      .then((response) => {
        setMessage(response.data.message);
        getClients();
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getClients();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      title="Manage Resellers"
      error={error}
      successMessage={message}
      loading={loading}
      containerMaxWidth="xl"
    >
      {ssidModal && (
        <DefaultSsidModal
          handleClose={setSsidModal}
          resellerUuid={ssidModal}
        />
      )}
      <Box pt={3} pb={3}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              id="client_name"
              label="Reseller Name"
              variant="outlined"
              fullWidth
              value={resellerName}
              onChange={(e) => setResellerName(e.target.value)}
              size="small"
            />
          </Grid>

          {isSuperAdmin() && (
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                id="bw_customer_id"
                label="Britewireless Customer Id"
                variant="outlined"
                fullWidth
                value={bwCustomerId}
                onChange={(e) => setBwCustomerId(e.target.value)}
                size="small"
                type="number"
              />
            </Grid>
          )}

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={createClient}
              disabled={resellerName === undefined || resellerName === ""}
            >
              Create Reseller
            </Button>
          </Grid>
        </Grid>
      </Box>

      {resellerData.map((reseller) => (
        <Box pt={2}>
          <Paper elevation={3}>
            <Box p={2} key={reseller.uuid}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item lg={2} xl={2} xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography variant="h6">{reseller.name}</Typography>
                        </Grid>
                        <Grid item>
                          <EditReseller
                            resellerInfo={reseller}
                            setLoading={setLoading}
                            callOnSuccess={getClients}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {reseller.parent_reseller_name && (
                        <Typography variant="caption">
                          Managed By <i>{reseller.parent_reseller_name}</i>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Tooltip title="Created Date & Time">
                        <AccessTimeIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Typography variant="body">
                        {moment.utc(reseller.created_at).local().format("LLL")}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid> */}

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ListIcon />}
                    onClick={() => {
                      history.push(`/reseller/${reseller.uuid}/devices`);
                    }}
                    size="small"
                  >
                    View Devices
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DevicesIcon />}
                    onClick={() => {
                      history.push(
                        `/reseller/${reseller.uuid}/assign-unassign-devices`
                      );
                    }}
                    size="small"
                  >
                    Manage Devices
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PermScanWifiIcon />}
                    onClick={() => setSsidModal(reseller.uuid)}
                    size="small"
                  >
                    Update Device Default Config
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<SimCardIcon />}
                    onClick={() => {
                      history.push(
                        `/reseller/${reseller.uuid}/assign-port-groups`
                      );
                    }}
                    size="small"
                  >
                    Assign Port Groups
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={
                      <GroupIcon
                        style={{
                          color: blue[500],
                        }}
                      />
                    }
                    onClick={() => {
                      history.push(`/reseller/${reseller.uuid}/users`);
                    }}
                    size="small"
                  >
                    Manage Users
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      ))}

      {resellerData.length === 0 && (
        <Box pt={10}>
          <Typography variant="h5" align="center">
            No Resellers.
          </Typography>
        </Box>
      )}
    </Layout>
  );
};

export default Resellers;
