import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { Alert } from "@material-ui/lab";
import Loader from "../components/Loader";

export default function DefaultSsidModal({ handleClose, resellerUuid }) {
  const [ssid, setSsid] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [validationError, setValidationError] = useState({});
  const [logoFile, setLogoFile] = useState(null);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [imgPreview, setImgPreview] = useState(null);
  const [fetchImageCalled, setFetchImageCalled] = useState(false);

  const updateConfig = () => {
    if (!ssid && !password && !logoFile) {
      return;
    }

    setLoading(true);

    const formData = new FormData();
    if (logoFile) formData.append("file", logoFile);
    formData.append("ssid", ssid);
    formData.append("password", password);
    formData.append("remove_logo", removeLogo);

    axios
      .post(`/reseller/${resellerUuid}/default_config`, formData)
      .then((response) => {
        setMessage({
          text: `Updated Default Config Successfully`,
          type: "success",
        });
      })
      .catch((error) => {
        setMessage({ text: error.response.data.detail, type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const loadCurrentConfig = () => {
    setLoading(true);
    axios
      .get(`/reseller/${resellerUuid}/default_config`)
      .then((response) => {
        setMessage(null);
        setSsid(response?.data?.ssid || "");
        setImgPreview(response?.data?.logo || "");
        setFetchImageCalled(Boolean(response?.data?.logo));
      })
      .catch((error) => {
        setMessage({ text: error.response.data.detail, type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const errors = {};
    if (ssid && ssid.length > 20) {
      errors.ssid = "SSID cannot be more than 20 characters";
    }
    if (password && password.length > 40) {
      errors.password = "Password cannot be more than 40 characters";
    }
    if (password && password.length && password.length < 8) {
      errors.password = "Password cannot be less than 8 characters";
    }

    setValidationError(errors);
  }, [ssid, password]);

  const handleFileUploader = (e) => {
    const file_size = e.target.files[0].size;

    const currentErrors = { ...validationError };

    if (file_size > 1024 * 1024) {
      currentErrors.logoFile = "File must be less than 1MB";
      setValidationError(currentErrors);
      return;
    } else {
      currentErrors.logoFile = null;
      setValidationError(currentErrors);
    }

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setImgPreview(objectUrl);

    setLogoFile(e.target.files[0]);
    setRemoveLogo(false);
    setFetchImageCalled(false);
  };

  return (
    <Dialog
      open={!!resellerUuid}
      onClose={() => handleClose(null)}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      {loading && <Loader />}
      <DialogTitle id="form-dialog-title">Device Default Config</DialogTitle>
      <DialogContent>
        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}
        <Button variant="contained" onClick={loadCurrentConfig} color="primary">
          Fetch Current Config
        </Button>

        <Box pb={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormControl
                error={!!(validationError && validationError.ssid)}
                sx={{ width: "100%" }}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  id="ssid"
                  label="SSID Prefix"
                  onChange={(e) => setSsid(e.target.value)}
                  placeholder="SSID Prefix"
                  value={ssid}
                />
                {validationError?.ssid && (
                  <FormHelperText id="ssid-error-text">
                    {validationError.ssid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl
                error={!!(validationError && validationError.password)}
                sx={{ width: "100%" }}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  id="password"
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  value={password}
                />
                {validationError?.password && (
                  <FormHelperText id="password-error-text">
                    {validationError.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                error={!!(validationError && validationError.logoFile)}
                sx={{ width: "100%" }}
              >
                <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleFileUploader}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="div"
                    style={{ marginBottom: "5px" }}
                  >
                    {"Upload Logo"}
                  </Button>
                </label>
                {imgPreview && (
                  <div
                    style={{
                      border: "2px solid rgb(29, 67, 84)",
                      position: "relative",
                    }}
                  >
                    <img
                      src={imgPreview}
                      style={{
                        display: "block",
                        margin: "auto",
                        maxWidth: "160px",
                        maxHeight: "37px",
                      }}
                      alt="upload-img"
                    />
                    <Tooltip
                      title={
                        fetchImageCalled
                          ? "Remove default logo"
                          : "Cancel logo update"
                      }
                    >
                      <Close
                        onClick={() => {
                          if (fetchImageCalled) setRemoveLogo(true);
                          setLogoFile(null);
                          setImgPreview(null);
                        }}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 1,
                          width: "12px !important",
                          height: "12px !important",
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                {validationError?.logoFile && (
                  <FormHelperText id="logoFile-error-text">
                    {validationError.logoFile}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(null)} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            loading ||
            validationError?.ssid ||
            validationError?.password ||
            (!ssid && !password && !logoFile)
          }
          onClick={updateConfig}
          color="primary"
        >
          Update Config
        </Button>
      </DialogActions>
    </Dialog>
  );
}
