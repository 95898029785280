import React, { useState, useEffect } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Grid,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import { getLogoPath } from "../partners/utils";
import ServerMaintenance from "../components/ServerMaintenance";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
      &nbsp; V {process.env.REACT_APP_VERSION}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginForm = () => {
  let history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`; // for all requests
      history.push("/devices");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/auth/login`, { email, password })
      .then((response) => {
        localStorage.setItem("token", response.data.access_token);
       
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`; // for all requests
        history.push("/devices");
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ServerMaintenance />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {isLoading && <Loader />}
        <Grid
          container
          className={classes.paper}
          direction="column"
          spacing={5}
        >
          <Grid item>
            <img src={getLogoPath()} height="100px" alt="sign in logo" />
          </Grid>
          <Grid item>
            <Typography component="h1" variant="h5">
              Sign In
            </Typography>
          </Grid>
          <Grid item>
            <form className={classes.form} noValidate>
              {error && <Alert severity="error">{error}</Alert>}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={login}
              >
                Sign In
              </Button>

              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/forgot-password");
                }}
              >
                Forgot Password?
              </Link>
            </form>
          </Grid>
        </Grid>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default LoginForm;
