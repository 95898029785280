exports.isSuperAdmin = () => {
  return JSON.parse(localStorage.getItem("userData"))?.is_super_admin === true;
};

exports.isResellerManager = () => {
  return (
    JSON.parse(localStorage.getItem("userData"))?.is_reseller_manager === true
  );
};

exports.getName = () => {
  return JSON.parse(localStorage.getItem("userData"))?.full_name;
};

exports.getEmail = () => {
  return JSON.parse(localStorage.getItem("userData"))?.email;
};

exports.hasActivateDeactivateAccess = () => {
  return JSON.parse(localStorage.getItem("userData"))
    ?.has_activate_deactivate_access;
};

exports.hasPlanChangeAccess = () => {
  return JSON.parse(localStorage.getItem("userData"))?.has_plan_change_access;
};

exports.hasNetworkingAccess = () => {
  return JSON.parse(localStorage.getItem("userData"))?.has_networking_access;
};
