import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../layouts/Layout";
import DevicesList from "../components/DevicesList";

const ViewDevices = () => {
  const { reseller_uuid } = useParams();

  const [loading, setLoading] = useState(false);
  const [resellerData, setResellerData] = useState(undefined);
  const [error, setError] = useState(undefined);

  const getResellerDetail = () => {
    setLoading(true);
    axios
      .get(`/reseller/${reseller_uuid}`)
      .then((response) => {
        setResellerData(response.data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getResellerDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      pageTitle={`Devices for ${resellerData?.name}`}
      error={error}
      loading={loading}
      containerMaxWidth="lg"
    >
      {resellerData && (
        <DevicesList
          reseller_uuid={resellerData.uuid}
          isLoading={loading}
          setError={setError}
        />
      )}
    </Layout>
  );
};

export default ViewDevices;
