import React from "react";
import "../styles/loader.css";
import { Alert } from "@material-ui/lab";
const isServerUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;

const ServerMaintenance = () => {
  return (
    <React.Fragment>
      {isServerUnderMaintenance === 'true' && (
        <Alert
          severity="warning"
          ContentProps={{
            sx: {
              display: "block",
              textAlign: "center",
            },
          }}
        >
          Server maintenance in progress. We'll be back online shortly. Thank
          you for your patience.
        </Alert>
      )}
    </React.Fragment>
  );
};

export default ServerMaintenance;
