import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Link,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Loader from "./Loader";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const ActivateDevice = (props) => {
  const classes = useStyles();
  const [portGroups, setPortGroups] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const { onClose, open, deviceData  } = props;
  const [message, setMessage] = useState(undefined);

  const activateDevice = () => {
    setLoading(true);
    axios
      .get(`/device/${deviceData.uuid}/activate`, {
        params: { port_group_uuid:selectedPlan },
      })
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          setMessage(data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  

  const fetchPortGroups = () => {
    setLoading(true);
    setMessage(undefined);
    axios
      .get(`/port-groups`)
      .then(({ data }) => {
        console.log(data);
        setPortGroups(data);
      })
      .catch((error) => {
        // console.log(error.response.data);
        setError("Error Occurred, Please retry !");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log(deviceData);
    if (deviceData && deviceData !== null) {
      fetchPortGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Dialog
          onClose={onClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            Activate Device {deviceData?.name}
          </DialogTitle>
          <DialogContent>
            {error && (
              <Box pt={3}>
                <Alert severity="error"> {error} </Alert>
              </Box>
            )}

            {message && (
              <Box pt={3}>
                <Alert severity="success"> {message} </Alert>
              </Box>
            )}
            {portGroups && (
              <Box pt={3} pb={3}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ minWidth: 180 }}
                    >
                      <InputLabel id="select-plan">
                        Select Port Group
                      </InputLabel>
                      <Select
                        labelId="select-plan"
                        id="plan"
                        value={selectedPlan}
                        onChange={(e) => setSelectedPlan(e.target.value)}
                        
                      >
                        {portGroups.map((plan, index) => (
                          <MenuItem value={plan.uuid} key={index}>
                            {plan.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      onClick={() => {
                        activateDevice();
                      }}
                    >
                      Activate Device
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default ActivateDevice;
