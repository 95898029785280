import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  Fade,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/List";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { getHeaderLogo, isNetrifi } from "../partners/utils";
import BusinessIcon from "@material-ui/icons/Business";
import { AssessmentOutlined } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import SimCardRoundedIcon from "@material-ui/icons/SimCardRounded";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
// import { Crisp } from "crisp-sdk-web";
import PermScanWifiIcon from "@material-ui/icons/PermScanWifi";
import DefaultSsidModal from "../containers/DefaultSsidModal";

const CustomNavButton = (props) => {
  let history = useHistory();
  return (
    <Button
      {...props}
      component={Link}
      variant={
        history.location && history.location.pathname +
          history.location.search ===
        props.to
          ? "contained"
          : "outlined"
      }
      color="secondary"
    >
      {props.children}
    </Button>
  );
};

const Header = () => {
  const [userData, setUserData] = useState();
  const [ssidModal, setSsidModal] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`; // for all requests
    }

    if (localStorage.getItem("userData") !== null) {
      const uData = JSON.parse(localStorage.getItem("userData"));
      setUserData(uData);
      //  Crisp.user.setEmail(uData.email);
      //  Crisp.user.setNickname(uData.full_name);
      //  Crisp.setTokenId(uData.uuid);
    } else {
      axios.get("/auth/me").then((response) => {
        setUserData(response.data);
        localStorage.setItem("userData", JSON.stringify(response.data));
        // Crisp.user.setEmail(response.data.email);
        // Crisp.user.setNickname(response.data.full_name);
        // Crisp.setTokenId(response.data.uuid);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: isNetrifi() ? "#ffff" : "" }}
    >
      {ssidModal && (
        <DefaultSsidModal handleClose={setSsidModal} resellerUuid={ssidModal} />
      )}
      <Box pt={1} pb={1}>
        <Toolbar color={{}}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center" spacing={3}>
                <Grid item>
                  <img
                    src={getHeaderLogo()}
                    width="auto"
                    height="65px"
                    alt="company logo"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" color="secondary">
                    Device Manager
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <CustomNavButton
                    startIcon={<GetAppIcon />}
                    to={{
                      pathname:
                        "https://iqsim.box.com/s/1xyc959ybwbh2sforxgup6ih15c5zflw",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MR40 Firmware
                  </CustomNavButton>
                </Grid>

                <Grid item>
                  <CustomNavButton startIcon={<ListIcon />} to="/devices">
                    Devices
                  </CustomNavButton>
                </Grid>

                <Grid item>
                  <CustomNavButton startIcon={<BusinessIcon />} to="/resellers">
                    Resellers
                  </CustomNavButton>
                </Grid>

                {userData && userData.is_reseller_manager && (
                  <React.Fragment>
                    <Grid item>
                      <CustomNavButton
                        startIcon={<PeopleIcon />}
                        to={`/reseller/${userData.reseller_uuid}/users?manager=1`}
                      >
                        Users
                      </CustomNavButton>
                    </Grid>
                    <Grid item>
                      <CustomNavButton
                        startIcon={<PermScanWifiIcon />}
                        onClick={() => setSsidModal(userData.reseller_uuid)}
                        size="small"
                        to="#"
                      >
                        Update Device Default Config
                      </CustomNavButton>
                    </Grid>
                  </React.Fragment>
                )}

                <Grid item>
                  <CustomNavButton
                    startIcon={<AssessmentOutlined />}
                    to={`/report`}
                  >
                    Report
                  </CustomNavButton>
                </Grid>

                {userData && userData.has_sim_mgmt_access && (
                  <Grid item>
                    <CustomNavButton
                      startIcon={<SimCardRoundedIcon />}
                      to={`/manage-sims`}
                    >
                      Manage Sim(s)
                    </CustomNavButton>
                  </Grid>
                )}

                {userData && userData.has_sim_mgmt_access && (
                  <Grid item>
                    <CustomNavButton
                      startIcon={<SimCardRoundedIcon />}
                      to={`/all-sims`}
                    >
                      Sim(s)
                    </CustomNavButton>
                  </Grid>
                )}
                {userData && userData.is_super_admin && (
                  <Grid item>
                    <CustomNavButton startIcon={<ListIcon />} to={`/sim-racks`}>
                      Sim Racks
                    </CustomNavButton>
                  </Grid>
                )}

                <CustomNavButton
                  startIcon={<ContactSupportIcon />}
                  to={{
                    pathname: " https://portal.iqsim.com/tickets-view",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </CustomNavButton>

                <Grid item>
                  <ProfileIconComponent userData={userData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

const ProfileIconComponent = (props) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        color="secondary"
        startIcon={<AccountCircleIcon />}
        onClick={handleClick}
        variant="outlined"
      >
        {props.userData?.full_name}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            history.push("/profile");
          }}
        >
          <ListItemIcon>
            <PeopleIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.clear();
            history.push("/");
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};


export default Header;
