import axios from "axios";
import React, { useEffect, useState } from "react";

import { Alert } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Button, Grid } from "@material-ui/core";

import Loader from "./Loader";
import DataTable from "./DataTable";
import DeviceDedicatedIPForm from "./DeviceDedicatedIPForm";

const DeviceDedicatedIP = (props) => {
  const [rules, setRules] = useState(null);
  const deviceSerial = props.deviceSerial || "UNIT12345";
  const [loading, setLoading] = useState(false);
  const { setError, setMessage } = props;

  const [openForm, setOpenForm] = useState(null);
  const [availableIps, setAvailableIps] = useState([]);

  const getRules = (deviceSerial) => {
    setLoading(true);
    axios
      .get(`/device/dedicated_ip/get_rules/${deviceSerial}`)
      .then((response) => {
        setRules(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.detail || "Error fetching rules");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteRule = (deviceSerial, ruleId) => {
    setLoading(true);
    axios
      .post(`/device/dedicated_ip/delete_rule/${deviceSerial}/${ruleId}`)
      .then((response) => {
        setMessage("Deleted rule successfully");
        getRules(deviceSerial);
      })
      .catch((error) => {
        console.log({error})
        setError(error?.response?.data?.detail || "Error deleting rule");
      }).finally(() => {
        getAvailableIps();
      });
  };

  useEffect(() => {
    getRules(deviceSerial);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAvailableIps();
  }, []);

  const getAvailableIps = () => {
    setLoading(true);

    axios
      .get(`/device/port_forward/get_available_ips?ip_type=dedicated`)
      .then((response) => {
        setAvailableIps(response.data);
      })
      .catch((error) => {
        setError("Error fetching available ips");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 2 },
    { field: "inbound_ip", headerName: "Inbound IP", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
          startIcon={<DeleteIcon />}
          color="secondary"
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            deleteRule(deviceSerial, params.row.id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  if (loading) return <Loader />;

  return (
    <>
      <Box>
        <Grid item>
          <Button
            onClick={() => setOpenForm(true)}
            color="primary"
            variant="contained"
            style={{ marginBottom: "5px" }}
          >
            Create New Rule
          </Button>
        </Grid>
        {openForm && (
          <DeviceDedicatedIPForm
            deviceSerial={deviceSerial}
            setError={setError}
            setMessage={setMessage}
            open={openForm}
            handleOpen={setOpenForm}
            availableIps={availableIps}
            callRules={getRules}
            callback={getAvailableIps}
          />
        )}
        {rules?.alloted_ips && (
          <DataTable
            loading={loading}
            data={rules.alloted_ips}
            columns={columns}
            metaData={{
              page: 1,
              page_size: 10,
              total: rules.alloted_ips.length,
            }}
            paginationMode="client"
          />
        )}
      </Box>
    </>
  );
};

export default DeviceDedicatedIP;
